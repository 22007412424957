<template>
  <div id="app">
    <div class="bor1">
      <dv-border-box-11 title="寰城科技数据分析系统"> </dv-border-box-11>
    </div>
    <div class="topInfo">
      <div class="top">
        <div class="item" v-for="(item, index) in list1" :key="index">
          <div class="bor1">
            <dv-border-box-8 :reverse="index === 1"></dv-border-box-8>
          </div>

          <div class="name skyBlue">
            {{ item.name }}
          </div>

          <div class="num">
            <span class="big">{{ item.num }}</span>
            <span class="small">个</span>
          </div>
        </div>
      </div>

      <div class="center">
        <div class="item" v-for="(item, index) in list2" :key="index">
          <div class="bor1"></div>

          <div class="name skyBlue">
            {{ item.name }}
          </div>

          <div class="num">
            <span class="big">{{ item.num }}</span>
            <span class="small">个</span>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="left">
          <div class="title skyBlue">男女使用比例</div>
          <div class="bor1">
            <dv-border-box-12> </dv-border-box-12>
          </div>

          <div class="box">
            <div class="left">
              <div class="info">
                <span class="sex skyBlue"> 男性 </span>

                <span class="num"> 48% </span>
              </div>

              <div class="imgBox"></div>
            </div>

            <div class="vs"></div>

            <div class="right">
              <div class="info">
                <span class="sex skyBlue"> 女性 </span>

                <span class="num"> 52% </span>
              </div>

              <div class="imgBox"></div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="title skyBlue">访问数据</div>
          <div class="bor1">
            <dv-border-box-12> </dv-border-box-12>
          </div>
          <div class="box2">
            <dv-charts :option="option" />
          </div>
        </div>
      </div>
    </div>

    <div class="bottomInfo">
      <div class="title skyBlue">今日实时订单</div>
      <div class="bor1">
        <dv-border-box-12> </dv-border-box-12>
      </div>

      <div class="table" :key="id">
        <dv-scroll-board :config="table" style="width: 100%; height: 100%" />
      </div>

      <div class="blur" v-if="show">
        <div class="message">
          <div class="icon"></div>

          <div class="skyBlue center">新订单提醒：</div>

          <div class="info">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";

const list = ref([]);

const id = ref(nanoid());

const message = ref("");

const newList = ref([]);

const show = ref(false);

const existTime = 5000 //弹窗存在时间

let index = 14;

const list1 = ref([
  {
    name: "场所",
    num: 15,
  },
  {
    name: "设备",
    num: 756,
  },
]);

const list2 = ref([
  {
    name: "商场",
    num: 4,
  },
  {
    name: "写字楼",
    num: 8,
  },
  {
    name: "其他",
    num: 3,
  },
]);

// 图表
const option = {
  title: {
    text: "",
    style: {
      fill: "#fff",
    },
  },
  legend: {
    data: ["设备数量", "访问人数"],
    bottom: 10,
    textStyle: {
      fontFamily: "Arial",
      fontSize: 13,
      fill: "#fff",
    },
  },
  xAxis: {
    data: ["三月", "四月", "五月", "六月", "七月"],
    axisLabel: {
      style: {
        rotate: 20,
        textAlign: "left",
        textBaseline: "top",
        fill: "#fff",
      },
    },
    axisTick: {
      show: false,
    },
    textStyle: { color: "#fff" },
  },
  yAxis: [
    {
      name: "设备数量",
      data: "value",
      min: 0,
      max: 800,
      interval: 100,
      splitLine: {
        style: {
          lineDash: [3, 3],
        },
      },
      axisLabel: {
        formatter: "{value}",
        style: {
          fill: "#fff",
        },
      },
      axisTick: {
        show: false,
      },
      nameTextStyle: {
        fill: "#fff",
        fontSize: 10,
      },
      axisLine: {
        style: {
          stroke: "#fff",
        },
      },
    },
    {
      name: "访问人数",
      data: "value",
      position: "right",
      min: 0,
      max: 20000,
      interval: 5000,
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: (dataItem) => dataItem.value / 1000 + "k",
        style: {
          fill: "#fff",
        },
      },
      axisTick: {
        show: false,
        style: {
          stroke: "#fff",
        },
      },
      nameTextStyle: {
        fill: "#fff",
        fontSize: 10,
      },
      axisLine: {
        style: {
          stroke: "#fff",
        },
      },
    },
  ],
  series: [
    {
      name: "设备数量",
      data: [268, 352, 510, 623, 756],
      type: "bar",
      gradient: {
        color: ["#37a2da", "#67e0e3"],
      },
      animationCurve: "easeOutBounce",
    },
    {
      name: "访问人数",
      data: [2358, 3942, 7533, 11089, 16858],
      type: "line",
      yAxisIndex: 1,
      animationCurve: "easeOutBounce",
    },
  ],
};

let table = ref({
  header: ["时间", "手机号", "场所"],
  data: [],
  rowNum: 15,
  headerBGC: "#04215D",
  oddRowBGC: "#ffffff22",
  evenRowBGC: "00000000",
  headerHeight: 30,
  waitTime: 2000,
});

const request = () => {
  const data = {
    current: 1,
    limit: 100,
  };

  axios({
    method: "POST",
    headers: { openid: "olKFp5e2r95HGahrTAttIazVzwf0" },
    url: "https://bucketapi.huanchengtech.com//api/main/zorderlist",
    data,
  }).then((res) => {
    index = 14;
    if (res.data.code == 1) {
      const arr = res.data.data.lists;
      list.value = [];
      newList.value = [];
      arr.map((item, i) => {
        const tempList = [
          item.create_time,
          handleTel(item.phone),
          handleText(item.aname),
        ];
        list.value.push(tempList);
        if (i < 15) {
          newList.value.push(tempList);
        }
      });
      message.value =
        newList.value[index][1] + "在" + newList.value[index][2] + "完成使用";

      table.value.data = list.value;
      id.value = nanoid();
    }
  });
};

request();

let time = getRandom(1000 , 10000)

let timer 

setInterval(() => {
  time = getRandom(1000 , 12000)
}, 1000);

function start() {
  setInterval(() => {
    request();
  }, 60000);

  setTimeout(() => {
    showInfo();
  }, time);

  // setInterval(() => {
  //   showInfo();
  // }, time);

}

start();

function showInfo() {
  if (!list.value.length) return;
  clearTimeout(timer)
  index ? index-- : (index = 14);
  message.value =
    newList.value[index][1] + "在" + newList.value[index][2] + "完成使用";
  show.value = true;
  timer = setTimeout(() => {
    show.value = false;
  }, existTime);
  
  setTimeout(() => {
    showInfo();
  }, time);
}

function handleTel(tel) {
  if (!tel) return "未授权用户";
  // 定义手机号正则表达式
  let reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/;
  // 判断手机号是否能够通过正则校验
  let isMobile = reg.test(tel);
  // 将手机号中间4位用*号进行显示
  return tel.replace(reg, "$1****$2");
}

function handleText(text) {
  if (text.length <= 2) return "**** " + text;
  const newText = text[text.length - 2] + "" + text[text.length - 1];
  return "**** " + newText;
}

// 获取指定范围随机数
function getRandom(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}
</script>



<style lang="less">
* {
  padding: 0;
  margin: 0;
  color: white;
  box-sizing: border-box;
  // transition: 0.2s ease-in-out;
}

html {
  font-size: 0.1vw;
}

.skyBlue {
  color: #01c1e3 !important;
}

#app {
  height: 100vh;
  width: 100vw;
  background: #000a3b;
  padding: 11vh 3vw 5vh;
  display: flex;

  position: relative;
}

.bor1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // z-index: -2;
}

.topInfo {
  // @h:13vh;
  // height: @h;
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-right: 2vw;
  // border: 1rem solid white;
  flex-direction: column;
  position: relative;

  > div {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .top {
    height: 20vh;

    .item {
      width: 46%;

      .name {
        height: 7vh;
        padding-left: 30rem;
        font-size: 14rem;
        display: flex;
        align-items: center;
        letter-spacing: 3rem;
      }

      .big {
        font-size: 25rem;
        margin-right: 3rem;
      }
    }
  }

  .center {
    height: 15vh;

    .item {
      width: 28%;
      background-image: url("/public/img/bor.png");
      background-size: 100% 100%;

      .name {
        height: 6vh;
        padding-left: 25rem;
        font-size: 11rem;
        display: flex;
        align-items: center;
        letter-spacing: 2rem;
      }

      .big {
        font-size: 18rem;
        margin-right: 2rem;
      }
    }
  }

  .item {
    position: relative;

    .num {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }

  .bottom {
    height: 42vh;
    display: flex;

    > div {
      position: relative;
    }

    > .left {
      width: 32%;
      margin-right: 2vw;
      position: relative;
      padding: 3vh 2vw;

      .box {
        z-index: 2;
        display: flex;
        position: relative;
        justify-content: space-between;
        transition: 0.2s ease-in-out;
        .vs {
          width: 1.6vw;
          height: 2vh;
          top: 55%;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          background-image: url("/public/img/vs.png");
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: 0;
          z-index: 3;
        }

        .left,
        .right {
          display: flex;
          flex-direction: column;
          height: 36vh;

          .info {
            // height: 5vh;
            padding: 6vh 0;
            .num {
              font-size: 12rem;
            }
          }

          .imgBox {
            flex: 1;
            background-image: url("/public/img/man.png");
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: 10% 0;
          }
        }

        .right {
          .imgBox {
            background-image: url("/public/img/woman.png");
          }
        }
      }
    }

    > .right {
      flex: 1;
      .box2 {
        height: 100%;
        margin: 0rem 14rem;
        padding: 3rem;
      }
    }
  }
}

.title {
  background-color: #042666;
  width: 8vw;
  position: absolute;
  top: -1vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  z-index: 2;
  border: 1rem solid #7ce7fd;
  border-radius: 3rem;
  font-size: 10rem;
}

.bottomInfo {
  flex: 1;
  position: relative;
  display: flex;
  .table {
    flex: 1;
    padding: 25rem 15rem 20rem;
    position: relative;
  }
  .blur {
    position: absolute;
    top: 25rem;
    left: 15rem;
    height: calc(100% - 45rem);
    width: calc(100% - 30rem);
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    display: flex;
    align-items: center;
    // background-image: url("/public/img/lh.gif");
    // background-size: 50% auto;
    // background-repeat: no-repeat;

    .message {
      // background: #01c1e388;
      background: #042666cc;
      display: flex;
      justify-content: center;
      flex: 1;
      height: 27rem;
      align-items: center;
      font-size: 14rem;
      > div {
        color: orange;
      }
      .icon {
        @size: 12rem;
        width: @size;
        height: @size;
        background-image: url("/public/img/message.png");
        background-size: 100% auto;
        margin-right: 5rem;
      }
      .center {
        margin-right: 6rem;
      }
    }
  }
}
</style>